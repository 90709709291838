import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { Margin, Resolution, usePDF } from "react-to-pdf";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebaseConfig";

function InvoicePDF({ setToggle }) {
  //states
  const [taxValue, setTextValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [balanceDue, setBalanceDue] = useState(0);
  const [uploadedImage, setUploadedImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceForm, setInvoiceForm] = useState({});

  const navigate = useNavigate();

  //functions
  const formatDate = (date) => {
    // Create a Date object from the input date string
    const inputDate = new Date(date);

    // Get the components of the date
    const month = inputDate.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  };
  const options = {
    resolution: Resolution.NORMAL,
    page: {
      margin: Margin.NONE,

      format: "A4",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };

  const { toPDF, targetRef } = usePDF(options);

  const handleSubmit = async () => {
    setIsLoading(true);
    const pdf = await toPDF({ options: options });

    const blob = pdf.canvas.pdf.output("blob");
    const refrence = collection(db, "inVoices");
    try {
      const storageRef = ref(storage, "invoices/invoice.pdf");
      await uploadBytes(storageRef, blob);
      const url = await getDownloadURL(storageRef);

      const doc = await addDoc(refrence, {
        pdf: url,
        inVoiceNo: invoiceForm.num,
        date: invoiceForm.date,
        dueDate: invoiceForm.dueDate,
        name: invoiceForm.bill,
      });
      console.log(doc, "doc");

      // navigate("/submit");
    } catch (error) {
      console.log(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };
  // hooks

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("invoiceData"));
    if (item) {
      setUploadedImage(item.uploadedImage);
      setItems(item.items);
      setInvoiceForm(item.invoiceForm);
      setAmountPaid(item.amountPaid);
      setBalanceDue(item.balanceDue);
      setSubtotal(item.subtotal);
      setTotalAmount(item.totalAmount);
      setTextValue(item.taxValue);
      setDiscountValue(item.discountValue);
      setShippingValue(item.shippingValue);
    }
  }, []);
  return (
    <>
      <Container sx={styles.cont}>
        <Box sx={styles.mainCont} style={{ margin: 0 }} ref={targetRef}>
          <Box sx={styles.logo}>
            <Container>
              <Box sx={styles.logo2}>
                <Box>
                  <Box
                    sx={{
                      height: "100px",
                      width: "300px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={uploadedImage}
                      style={{
                        height: "100px",
                        width: "300px",
                        // objectFit: "contain",
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography sx={styles.h1}>INVOICE</Typography>
                </Box>
              </Box>
            </Container>
          </Box>
          <Container>
            <Box sx={styles.col}>
              <Box>
                <Grid container sx={{ marginTop: "20px" }}>
                  <Grid items lg={8}>
                    <Box
                      sx={[
                        styles.row,
                        {
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        },
                      ]}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",

                          gap: "2px",
                        }}
                      >
                        <Typography sx={[styles.text, { fontWeight: 600 }]}>
                          American Design Studios
                        </Typography>
                        <Typography sx={styles.text}>
                          info@americandesignstudios.com
                        </Typography>
                        <Typography sx={styles.text}>(877) 818-9110</Typography>
                        <Box
                          sx={[
                            styles.col,
                            {
                              gap: "5px",
                            },
                          ]}
                        >
                          <Box
                            sx={[
                              styles.col,
                              {
                                justifyContent: "flex-start",
                                alignItems: "start",
                                gap: "5px",
                                marginTop: "15px",
                              },
                            ]}
                          >
                            <Typography sx={styles.text}>Bill To:</Typography>
                            {invoiceForm.bill &&
                              invoiceForm.bill
                                .split(",")
                                .map((part, index, array) => (
                                  <Typography
                                    key={index}
                                    sx={[
                                      styles.text,
                                      {
                                        wordBreak: "break-all",
                                        width: "350px",
                                        textAlign: "left",
                                        fontWeight: index === 0 ? 600 : 500,
                                      },
                                    ]}
                                  >
                                    {part}
                                  </Typography>
                                ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid items lg={4}>
                    <Box sx={styles.col2}>
                      <Box sx={styles.row3}>
                        <Typography sx={styles.font2}>Date:</Typography>
                        <Typography
                          textAlign={"left"}
                          sx={[
                            styles.font,
                            { wordBreak: "break-all", fontSize: "16px" },
                          ]}
                        >
                          {invoiceForm.date && formatDate(invoiceForm.date)}
                        </Typography>
                      </Box>
                      <Box sx={styles.row3}>
                        <Typography sx={styles.font2}>Due Date:</Typography>
                        <Typography
                          textAlign={"left"}
                          sx={[
                            styles.font,
                            { wordBreak: "break-all", fontSize: "16px" },
                          ]}
                        >
                          {invoiceForm.dueDate &&
                            formatDate(invoiceForm.dueDate)}
                        </Typography>
                      </Box>
                      {/* <Box sx={styles.row3}>
                          <Typography sx={styles.font2}>Name</Typography>
                          <Typography
                            textAlign={"right"}
                            sx={[
                              styles.font,
                              { wordBreak: "break-all", fontSize: "16px" },
                            ]}
                          >
                            {invoiceForm.Name}
                          </Typography>
                        </Box> */}
                      <Box sx={styles.row3}>
                        <Typography sx={styles.font2}>
                          Payment Terms:
                        </Typography>
                        <Typography
                          textAlign={"right"}
                          sx={[
                            styles.font,
                            { wordBreak: "break-all", fontSize: "16px" },
                          ]}
                        >
                          {invoiceForm.payment}
                        </Typography>
                      </Box>

                      <Box
                        sx={[
                          styles.row3,
                          {
                            position: "relative",
                            backgroundColor: "#163C6B",
                            color: "white",
                            paddingX: "20px",
                            paddingY: "5px",
                            textAlign: "left",
                            width: "240px",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              right: "-23px",
                              width: 0,
                              height: 0,
                              borderTop: "20px solid transparent",
                              borderBottom: "17px solid transparent",
                              borderLeft: "24px solid #163C6B",
                            },
                          },
                        ]}
                      >
                        <Typography sx={styles.font2}>Invoice No:</Typography>
                        <Typography
                          textAlign={"left"}
                          sx={[
                            styles.font,
                            { wordBreak: "break-all", fontSize: "16px" },
                          ]}
                        >
                          {invoiceForm.num}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={styles.items}>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#E6E6E6",
                    color: "black",
                    paddingY: "10px",
                  }}
                >
                  <Grid item lg={1}>
                    <Box sx={styles.rowItem}>
                      <Typography
                        sx={[styles.font, { paddingLeft: "10px" }]}
                        textAlign={"center"}
                      >
                        SL.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={5}>
                    <Box sx={styles.rowItem}>
                      <Typography sx={[styles.font]} textAlign={"center"}>
                        Items Description
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2} sx={styles.rowItem}>
                    <Box sx={styles.rowItem}>
                      <Typography sx={styles.font} textAlign={"center"}>
                        Quantity
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2} sx={styles.rowItem}>
                    <Box sx={styles.rowItem}>
                      <Typography sx={styles.font} textAlign={"center"}>
                        Rate
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2} sx={styles.rowItem}>
                    <Box sx={styles.rowItem}>
                      <Typography sx={styles.font}>Amount</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",

                    marginTop: "20px",
                  }}
                >
                  {items.map((items, index, arr) => {
                    return (
                      <Grid
                        key={index}
                        container
                        sx={{
                          // paddingY: "8px",
                          borderBottom: "2px solid #B0B0AE",
                        }}
                      >
                        <Grid
                          item
                          lg={1}
                          maxWidth={"100%"}
                          sx={{
                            backgroundColor: "#EDEFEC",
                            paddingY: "15px",
                            paddingX: "12px",
                          }}
                        >
                          <Typography textAlign={"left"} sx={styles.font}>
                            {index + 1}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          lg={5}
                          maxWidth={"100%"}
                          sx={{
                            backgroundColor: "#FBFBF9",
                            paddingY: "15px",
                            paddingX: "12px",
                          }}
                        >
                          <Typography
                            textAlign={"left"}
                            sx={[styles.font, { wordBreak: "break-all" }]}
                          >
                            {items.item}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          maxWidth={"100%"}
                          sx={{
                            backgroundColor: "#EDEFEC",
                            paddingY: "15px",
                            paddingX: "12px",
                          }}
                        >
                          <Typography textAlign={"left"} sx={styles.font}>
                            {items.quantity}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          maxWidth={"100%"}
                          sx={{
                            backgroundColor: "#FBFBF9",
                            paddingY: "15px",
                            paddingX: "12px",
                          }}
                        >
                          <Typography textAlign={"left"} sx={styles.font}>
                            ${items.rate}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          sx={{
                            backgroundColor: "#EDEFEC",
                            paddingY: "15px",
                            paddingX: "12px",
                          }}
                        >
                          <Typography
                            width={"100%"}
                            textAlign={"left"}
                            sx={{ fontFamily: "Poppins, sans-serif" }}
                          >
                            ${items.amount}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>

              <Box>
                <Grid container sx={{ marginTop: "40px" }}>
                  <Grid items lg={8}>
                    <Box sx={styles.col3}>
                      {invoiceForm.notes && (
                        <>
                          <Typography sx={styles.font}>Notes:</Typography>
                          <Typography
                            sx={[
                              styles.text,
                              {
                                fontFamily: "Poppins, sans-serif",
                                maxWidth: "350px",

                                wordWrap: "break-word",
                              },
                            ]}
                          >
                            {invoiceForm.notes}
                          </Typography>
                        </>
                      )}

                      {invoiceForm.cond && (
                        <Box sx={{ textAlign: "left" }}>
                          {console.log(invoiceForm.cond, "asdas")}
                          <Typography sx={styles.font} variant="h6">
                            Terms & Conditions:
                          </Typography>
                          <Typography sx={[styles.text]}>
                            {invoiceForm.cond}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid items lg={4}>
                    <Box sx={styles.col2}>
                      <Box sx={styles.row3}>
                        <Typography sx={styles.font2}>SubTotal: </Typography>
                        <Typography sx={styles.font}>${totalAmount}</Typography>
                      </Box>

                      {!taxValue == 0 && (
                        <Box sx={[styles.row3, { alignItems: "center" }]}>
                          <Typography sx={styles.font2}>Tax(%): </Typography>

                          <Typography>{taxValue}%</Typography>
                        </Box>
                      )}
                      {!discountValue == 0 && (
                        <Box sx={[styles.row3, { alignItems: "center" }]}>
                          <Typography sx={styles.font2}>Discount:</Typography>

                          <Typography sx={styles.font}>
                            ${discountValue}
                          </Typography>
                        </Box>
                      )}
                      {!shippingValue == 0 && (
                        <Box sx={[styles.row3, { alignItems: "center" }]}>
                          <Typography sx={styles.font2}>Shipping </Typography>

                          <Typography sx={styles.font}>
                            ${shippingValue}
                          </Typography>
                        </Box>
                      )}

                      <Box sx={styles.row3}>
                        <Typography sx={styles.font2}>Total:</Typography>
                        <Typography sx={styles.font}>${subtotal}</Typography>
                      </Box>
                      <Box sx={[styles.row3, { alignItems: "center" }]}>
                        <Typography sx={styles.font2}>Amount Paid:</Typography>
                        <Typography sx={styles.font}>${amountPaid}</Typography>
                      </Box>
                      <Box sx={styles.row4}>
                        <Typography sx={styles.font2}>Balance Due:</Typography>
                        <Typography sx={styles.font}>${balanceDue}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
          <Box sx={[styles.logo, { paddingY: 0, marginTop: "50px" }]}>
            <Box sx={styles.clipPath}>Thank you for your business</Box>
          </Box>
        </Box>
      </Container>

      <Container sx={styles.cont}>
        <Box sx={styles.row3}>
          <Button
            variant="contained"
            sx={[
              styles.butn,
              {
                backgroundColor: "white",
                color: "#0070BA",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: "white",
                  color: "#0070BA",
                },
              },
            ]}
            size="large"
            onClick={() => {
              setToggle(true);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={[
              styles.butn,
              {
                backgroundColor: "white",
                color: "#0070BA",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: "white",
                  color: "#0070BA",
                },
              },
            ]}
            size="large"
            onClick={handleSubmit}
          >
            Download PDF
            {isLoading && (
              <CircularProgress sx={{ marginLeft: "5px" }} size={15} />
            )}
          </Button>
        </Box>
      </Container>
    </>
  );
}

const styles = {
  butn: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
  },
  color: {
    backgroundColor: "#0070BA",
  },
  text: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    fontSize: "16px",
  },
  rowItem: {
    display: "flex",
    // width: "100%",
    fontFamily: "Poppins, sans-serif",
    alignItems: "center",
  },
  items: {
    width: "100%",
    // border: "1px solid grey",
  },
  main: { paddingY: "10px" },
  cont: {
    maxWidth: { lg: "950px" },
  },
  clipPath: {
    position: "relative",
    display: "flex",
    // clipPath: "polygon(0% 0%, 92% 0, 100% 50%, 93% 100%, 0% 100%) !important",
    backgroundColor: "#163C6B",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    width: "260px",
    paddingY: "10px",
    fontFamily: "Poppins, sans-serif",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: "-23px",
      width: 0,
      height: 0,
      borderTop: "25px solid transparent",
      borderBottom: "20px solid transparent",
      borderLeft: "24px solid #163C6B", // Adjust the height as needed
    },
  },
  row2: {
    display: "flex",
    gap: "2px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  row: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },

  row4: {
    position: "relative",
    display: "flex",
    width: "210px",
    height: "40px",
    justifyContent: "space-between",
    alignItems: "center",

    backgroundColor: "#E31C26",
    color: "white",
    paddingX: "10px",
    paddingY: "5px",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: "-23px",
      width: 0,
      height: 0,
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
      borderLeft: "24px solid #E31C26",
    },
  },
  row3: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  font: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    fontSize: "18px",
  },
  font2: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "18px",
  },
  font3: {
    fontFamily: "Poppins, sans-serif",
  },
  absolute: {
    position: "absolute",
    top: 5,
    left: 0,
    cursor: "pointer",
  },
  mainCont: {
    backgroundColor: "white",
    // paddingBottom: "40px",
  },
  logo: {
    width: "100%",
    backgroundColor: "#E6E6E6",
    paddingY: "30px",
  },

  logo2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  h1: {
    fontSize: "45px",
    letterSpacing: "10px",
    color: "#373B44",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bolder",
  },
  box1: {
    height: "42px",
    width: "147px",
    backgroundColor: "#03507C",
    clipPath: " polygon(20% 0, 100% 0%, 100% 99%, 0% 100%);",
  },
  box2: {
    height: "42px",
    width: "94px",
    backgroundColor: "#03507C",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    // alignItems: "flex-end",
    justifyContent: "center",
  },
  col3: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "flex-start",
    // alignItems: "flex-end",
    // justifyContent: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },

  logoText: {
    fontFamily: "Bebas Neue, sans-serif",
    color: "#016AAE",
    fontSize: "55px",
    fontWeight: 400,
    lineHeight: "normal",
  },
  logoImg: {
    height: "120px",
    width: "220px",
    border: "1px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  between: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
};

export default InvoicePDF;
