import React from "react";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
function GridComp({ item, index, handleItemChange }) {
  const handleChange = (key, value) => {
    const items = { ...item, [key]: value };
    handleItemChange(index, items);
  };
  return (
    <>
      <Box sx={styles.main}>
        <Grid container sx={{ border: "1px solid black", paddingY: "8px" }}>
          <Grid items lg={6}>
            <Box sx={styles.fullWidth}>
              <TextField
                type="text"
                placeholder="Enter your items description"
                name="item"
                value={item.item}
                size="small"
                fullWidth
                multiline
                rows={2}
                sx={{ paddingRight: "2px", width: "98%" }}
                onChange={(e) => handleChange("item", e.target.value)}
                // style={{
                //   width: "100%",
                //   border: "none",

                //   //   outline: "none",
                //   fontFamily: "Poppins, sans-serif",
                //   fontSize: "15px",
                // }}
              />
              {/* <TextField variant="standard" onBeforeInput={"none"}  /> */}
            </Box>
          </Grid>
          <Grid items lg={2}>
            {" "}
            <input
              name="quantity"
              value={item.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
              type="number"
              placeholder="1"
              style={{
                width: "100%",
                border: "none",

                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
              }}
            />
          </Grid>
          <Grid items lg={2}>
            {" "}
            <input
              type="number"
              name="rate"
              onChange={(e) => handleChange("rate", e.target.value)}
              value={item.rate}
              placeholder="0.00"
              style={{
                width: "100%",
                border: "none",

                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
              }}
            />
          </Grid>
          <Grid items lg={2}>
            <Typography
              width={"100%"}
              textAlign={"left"}
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              {item.amount}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default GridComp;
const styles = {
  main: { paddingX: "5px" },
  fullWidth: {
    width: "100%",
  },
};
