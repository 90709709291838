import React, { useEffect } from "react";
import {
  Box,
  Container,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
function Invoice() {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, isLoading] = useState(false);
  const [query, setQuery] = useState();

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setQuery(value);
    console.log(filterData, "filterData");
    const filteredData = filterData.filter((item) => {
      return (
        item.inVoiceNo.includes(value) ||
        item.name.toLowerCase().includes(value)
      );
    });
    console.log(filteredData, "filteredData");
    setData(filteredData);
  };
  const getData = async () => {
    isLoading(true);
    const ref = collection(db, "inVoices");
    try {
      const docRef = await getDocs(ref);

      const docData = docRef.docs.map((doc) => {
        const data = doc.data();
        console.log(data.date, "date");
        const formatedDate1 = data.date.split("T")[0];
        const formatedDate2 = data.dueDate.split("T")[0];
        return { ...data, formatedDate1, formatedDate2 };
      });
      console.log(docData, "docData");
      setData(docData);
      setFilterData(docData);
    } catch (error) {
      console.log(error, "error");
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Box sx={styles.main}>
        <Container sx={styles.cont}>
          <Box sx={styles.col}>
            <Box textAlign={"center"} sx={styles.row3}>
              <Typography variant="h4" sx={styles.font3}>
                Invoices
              </Typography>
              <FormControl variant="standard">
                <Input
                  sx={{ color: "white", borderColor: "white" }}
                  id="input-with-icon-adornment"
                  onChange={handleSearch}
                  value={query}
                  placeholder="Search with Invoice No"
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: "white" }}>
                      <SearchIcon sx={{ color: "white" }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>

            {loading && (
              <Typography variant="h3" sx={styles.font3}>
                Loading...
              </Typography>
            )}
            {data.length === 0 && loading === false && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h3" sx={styles.font3}>
                  No Invoices Yet!
                </Typography>
              </Box>
            )}
            {data.length > 0 && (
              <>
                <Box sx={styles.box}>
                  <Grid container>
                    <Grid item lg={4}>
                      <Typography sx={styles.font}>Name</Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography sx={styles.font}>Invoice No</Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography sx={styles.font}>Date</Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography sx={styles.font}>Due Date</Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography sx={styles.font}>Pdf</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={styles.scroll}>
                  {data?.map((data) => {
                    return (
                      <>
                        <Box sx={styles.box} marginTop={"10px"}>
                          <Grid container>
                            <Grid item lg={4}>
                              <Typography sx={styles.font}>
                                {" "}
                                {data.name}
                              </Typography>
                            </Grid>
                            <Grid item lg={2}>
                              <Typography sx={styles.font}>
                                {data.inVoiceNo}
                              </Typography>
                            </Grid>
                            <Grid item lg={2}>
                              <Typography sx={styles.font}>
                                {data.formatedDate1}
                              </Typography>
                            </Grid>
                            <Grid item lg={2}>
                              <Typography sx={styles.font}>
                                {data.formatedDate2}
                              </Typography>
                            </Grid>
                            <Grid item lg={2} sx={styles.row}>
                              <a
                                href={data.pdf}
                                target="_blank"
                                style={{ color: "inherit" }}
                              >
                                <AiOutlineEye />
                              </a>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Invoice;
const styles = {
  main: {},
  box: {
    width: "100%",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "8px",
    paddingY: "5px",
  },
  cont: {
    border: "1px solid  white",

    marginTop: "60px",
    // backgroundColor: "white",
    borderRadius: "8px",
    backdropFilter: "blur(200px)",
  },
  butn: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
  },
  color: {
    backgroundColor: "#0070BA",
  },
  rowItem: {
    display: "flex",
    width: "100%",
    fontFamily: "Poppins, sans-serif",
  },
  row2: {
    display: "flex",
    gap: "2px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  col: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    padding: "20px",
  },
  row: {
    display: "flex",
    // backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
  },
  row3: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  font: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    fontSize: "18px",
    color: "#03507C",
  },
  font2: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "18px",
  },
  font3: {
    fontFamily: "Poppins, sans-serif",
    color: "white",
    fontWeight: "bold",
  },
  scroll: {
    maxHeight: "700px",
    padding: "5px",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin", // Add scrollbar on Webkit browsers
    scrollbarColor: "#ffffff #ffffff", // Define the scrollbar color and track color
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "##143F5D",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#156192",
    },
  },
};
