import React, { useEffect, useRef } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  FormLabel,
  Button,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { GoPlus } from "react-icons/go";
import { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import GridComp from "../Components/GridComp";
import toast from "react-hot-toast";
import { json, useNavigate } from "react-router-dom";
import InvoicePDF from "./InvoicePDF";
import logo from "../../src/assests/newLogo.png";
function Home() {
  //states
  const [taxValue, setTextValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);

  const [amountPaid, setAmountPaid] = useState(0);
  const [items, setItems] = useState([
    {
      item: "",
      quantity: 1,
      rate: "",
      amount: "",
    },
  ]);
  // const [value, onChange] = useState(new Date());
  const [toggle, setToggle] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [balanceDue, setBalanceDue] = useState(0);
  const [uploadedImage, setUploadedImage] = useState(logo);
  const [taxToggle, setTaxToggle] = useState(false);
  const [discountToggle, setDiscountToggle] = useState(false);
  const [shippingToggle, setShippingToggle] = useState(false);
  const [taxToggle2, setTaxToggle2] = useState(false);
  const [discountToggle2, setDiscountToggle2] = useState(false);
  const [shippingToggle2, setShippingToggle2] = useState(false);
  const [age, setAge] = React.useState("flat");

  const [invoiceForm, setInvoiceForm] = useState({
    date: new Date(),
    dueDate: new Date(),
    bill: "",
    ship: "",
    payment: "",
    num: "",
    Name: "",
    cond: "",
    notes: "",
  });
  const fileInputRef = useRef();

  const navigate = useNavigate();

  //functions
  const handleChange = (event) => {
    setShippingValue(0);
    setTextValue(0);
    setDiscountValue(0);
    setAge(event.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(URL.createObjectURL(file));
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };
  const handleImgRemove = () => {
    setUploadedImage(false);
  };

  const handleAddItems = () => {
    setItems([
      ...items,
      {
        item: "",
        quantity: 1,
        rate: "",
        amount: "",
      },
    ]);
  };
  const handleRemoveItems = () => {
    if (items.length > 1) {
      const itemsList = [...items];
      itemsList.pop();
      setItems(itemsList);
      const value = calculateTotalamount(itemsList);
      setTotalAmount(value);
    }
  };

  const handleItemChange = (index, item) => {
    const updatedItem = [...items];
    updatedItem[index] = item;

    const quantity = parseFloat(item.quantity) || 0;
    const rate = parseFloat(item.rate) || 0;
    const subamount = rate * quantity;
    updatedItem[index].amount = subamount;
    setItems(updatedItem);

    const value = calculateTotalamount(updatedItem);
    setTotalAmount(value);
  };

  const calculateSubtotal = () => {
    console.log(totalAmount, shippingValue, discountValue);
    if (age == "flat") {
      let subTotal = totalAmount + shippingValue + taxValue - discountValue;
      console.log(subTotal, "sub");

      return subTotal;
    } else {
      let total =
        (shippingValue / 100) * totalAmount +
        (taxValue / 100) * totalAmount -
        (discountValue / 100) * totalAmount;
      let subTotal2 = totalAmount + total;
      return subTotal2;
    }
  };

  const calculateTotalamount = (itemArray) => {
    let total = 0;
    itemArray.forEach((item) => {
      const amount = parseFloat(item.amount);
      total += amount;
    });
    return total;
  };

  const handleAmountPaid = (e) => {
    const value = e.target.value;
    setAmountPaid(value);
    const amount = subtotal - value;
    console.log(amount, "amount");
    setBalanceDue(amount);
  };
  const handleAmountPaid2 = () => {
    const amount = subtotal - amountPaid;
    console.log(amount, "amount1");
    setBalanceDue(amount);
  };
  const handletoggle = (name) => {
    if (name == "tax") {
      setTaxToggle(!taxToggle);
      setTextValue(0);
    } else if (name == "disc") {
      setDiscountToggle(!discountToggle);
      setDiscountValue(0);
    } else {
      setShippingToggle(!shippingToggle);
      setShippingValue(0);
    }
  };
  const handletoggle2 = (name) => {
    if (name == "tax2") {
      setTaxToggle2(!taxToggle2);
      setTextValue(0);
    } else if (name == "disc2") {
      setDiscountToggle2(!discountToggle2);
      setDiscountValue(0);
    } else {
      setShippingToggle2(!shippingToggle2);
      setShippingValue(0);
    }
  };
  const [formErrors, setFormErrors] = useState({});
  const handleForm = (key, value) => {
    setInvoiceForm({ ...invoiceForm, [key]: value });
    setFormErrors({ ...formErrors, [key]: "" });
  };

  const handleSubmit = () => {
    const { bill, ship, date, dueDate, payment, num, cond, notes } =
      invoiceForm;
    let errors = {};
    const validateFields = (value, field) => {
      console.log(field, "field");
      if (!value) {
        errors[field] = `${field} cannot be empty.`;
      }
    };
    validateFields(bill, "bill");
    validateFields(date, "date");
    validateFields(dueDate, "dueDate");
    validateFields(payment, "payment");
    validateFields(num, "num");
    // validateFields(Name, "Name");
    // validateFields(notes, "notes");
    if (!uploadedImage) {
      toast.error("logo is required", {
        duration: 3000,
        position: "top-right",
      });
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0 || !uploadedImage) {
      console.log("validationError", errors);
    } else {
      localStorage.setItem(
        "invoiceData",
        JSON.stringify({
          invoiceForm,
          items,
          uploadedImage,
          subtotal,
          totalAmount,
          amountPaid,
          balanceDue,
          discountValue,
          taxValue,
          shippingValue,
        })
      );
      setToggle(false);
    }
  };
  // hooks

  useEffect(() => {
    const value = calculateSubtotal();
    console.log(value, "value");
    setSubtotal(value);
  }, [discountValue, taxValue, shippingValue, totalAmount]);

  useEffect(() => {
    handleAmountPaid2();
  }, [totalAmount]);

  useEffect(() => {
    handleAmountPaid2();
  }, [subtotal]);

  useEffect(() => {
    setSubtotal(calculateSubtotal());
  }, [items]);
  useEffect(() => {
    console.log(invoiceForm, "invoiceForm");
  }, [invoiceForm]);
  return toggle ? (
    <div>
      {
        <Box sx={styles.main}>
          <Container sx={styles.cont}>
            <Box sx={styles.mainCont}>
              <Box sx={styles.logo}>
                <Container sx={styles.cont}>
                  <Box sx={styles.logo2}>
                    <Box>
                      {uploadedImage ? null : (
                        <Box sx={styles.logoImg} onClick={handleBoxClick}>
                          <Typography
                            sx={{
                              fontFamily: "Bebas Neue, sans-serif",
                              color: "#016AAE",
                              fontSize: "25px",
                            }}
                          >
                            Add Your Logo 120x100
                          </Typography>

                          <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </Box>
                      )}

                      {uploadedImage ? (
                        <Box
                          sx={{
                            height: "100px",
                            width: "300px",
                            position: "relative",
                          }}
                        >
                          <img
                            src={uploadedImage}
                            style={{
                              height: "100px",
                              width: "300px",
                              // objectFit: "contain",
                            }}
                          />
                          {/* <Box sx={styles.absolute} onClick={handleImgRemove}>
                            <RxCrossCircled size={"30px"} />
                          </Box> */}
                        </Box>
                      ) : null}
                    </Box>

                    <Box>
                      <Typography sx={styles.h1}>INVOICE</Typography>
                    </Box>
                  </Box>
                </Container>
              </Box>
              <Container>
                <Box sx={[styles.col, { marginTop: "20px" }]}>
                  <Box>
                    <Grid container>
                      <Grid items lg={6}>
                        <Box
                          sx={[
                            styles.row,
                            {
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            },
                          ]}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              marginTop: "10px",
                              gap: "4px",
                            }}
                          >
                            <Typography sx={[styles.text, { fontWeight: 600 }]}>
                              American Design Studios
                            </Typography>
                            <Typography sx={styles.text}>
                              info@americandesignstudios.com
                            </Typography>
                            <Typography sx={styles.text}>
                              (877) 818-9110
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "10px",
                              }}
                            >
                              <Box>
                                <Typography textAlign={"left"} sx={styles.text}>
                                  Bill To:
                                </Typography>
                                <TextField
                                  size="small"
                                  name="bill"
                                  error={Boolean(formErrors.bill)}
                                  multiline
                                  rows={3}
                                  sx={[styles.text, { marginRight: "15px" }]}
                                  value={invoiceForm.bill}
                                  onChange={(e) => {
                                    handleForm(e.target.name, e.target.value);
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid items lg={6}>
                        <Box sx={styles.col2}>
                          <Box sx={styles.row}>
                            <Typography sx={styles.font}>Date</Typography>
                            <DatePicker
                              value={invoiceForm.date}
                              name="date"
                              format="MM/dd/y"
                              onChange={(value) => {
                                handleForm("date", value);
                              }}
                            />
                          </Box>
                          <Box sx={styles.row}>
                            <Typography sx={styles.font}> Due Date</Typography>
                            <DatePicker
                              value={invoiceForm.dueDate}
                              name="dueDate"
                              format="MM/dd/y"
                              onChange={(value) => {
                                handleForm("dueDate", value);
                              }}
                            />
                          </Box>
                          {/* <Box sx={styles.row}>
                            <Typography sx={styles.font}>Name</Typography>
                            <TextField
                              size="small"
                              type="string"
                              sx={{ width: "160px" }}
                              name="Name"
                              error={Boolean(formErrors.Name)}
                              value={invoiceForm.Name}
                              onChange={(e) => {
                                handleForm(e.target.name, e.target.value);
                              }}
                            />
                          </Box> */}
                          <Box sx={styles.row}>
                            <Typography sx={styles.font}>
                              Payment Terms:
                            </Typography>
                            <TextField
                              size="small"
                              sx={{ width: "160px" }}
                              name="payment"
                              error={Boolean(formErrors.payment)}
                              value={invoiceForm.payment}
                              onChange={(e) => {
                                handleForm(e.target.name, e.target.value);
                              }}
                            />
                          </Box>

                          <Box sx={styles.row}>
                            <Typography sx={styles.font}>Invoice No</Typography>
                            <TextField
                              type="number"
                              size="small"
                              sx={{ width: "160px" }}
                              name="num"
                              error={Boolean(formErrors.num)}
                              value={invoiceForm.num}
                              onChange={(e) => {
                                handleForm(e.target.name, e.target.value);
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={styles.items}>
                    <Grid
                      container
                      sx={{
                        backgroundColor: "#E6E6E6",
                        color: "black",
                        paddingY: "10px",
                      }}
                    >
                      <Grid items lg={6} sx={[styles.rowItem]}>
                        <Box sx={styles.rowItem}>
                          <Typography
                            sx={[styles.font, { paddingLeft: "10px" }]}
                            textAlign={"center"}
                          >
                            Items Description
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid items lg={2} sx={styles.rowItem}>
                        <Box sx={styles.rowItem}>
                          <Typography sx={styles.font} textAlign={"center"}>
                            Quantity
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid items lg={2} sx={styles.rowItem}>
                        <Box sx={styles.rowItem}>
                          <Typography sx={styles.font} textAlign={"center"}>
                            Rate
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid items lg={2} sx={styles.rowItem}>
                        <Box sx={styles.rowItem}>
                          <Typography sx={styles.font}>Amount</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      {items.map((items, index) => {
                        return (
                          <GridComp
                            key={index}
                            item={items}
                            index={index}
                            handleItemChange={handleItemChange}
                          />
                        );
                      })}
                      <Box sx={styles.between}>
                        <Button
                          variant="contained"
                          sx={styles.butn}
                          onClick={handleAddItems}
                        >
                          Add Items
                        </Button>
                        <Button
                          variant="contained"
                          sx={styles.butn}
                          onClick={handleRemoveItems}
                        >
                          Remove Items
                        </Button>
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid items lg={6}>
                        <Box sx={styles.col3}>
                          <Typography sx={styles.font} variant="h6">
                            Notes:
                          </Typography>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Notes"
                            sx={{ width: "350px" }}
                            name="notes"
                            error={Boolean(formErrors.notes)}
                            value={invoiceForm.notes}
                            onChange={(e) => {
                              handleForm(e.target.name, e.target.value);
                            }}
                          />
                          <Box sx={{ textAlign: "left" }}>
                            <Typography sx={styles.font} variant="h6">
                              Terms & Conditions:
                            </Typography>
                            <TextField
                              variant="outlined"
                              size="small"
                              placeholder="Terms & Conditions"
                              sx={{ width: "350px" }}
                              name="cond"
                              error={Boolean(formErrors.cond)}
                              value={invoiceForm.cond}
                              onChange={(e) => {
                                handleForm(e.target.name, e.target.value);
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid items lg={6}>
                        <Box sx={styles.col2}>
                          <Box sx={styles.row}>
                            <Typography sx={styles.font2}>SubTotal:</Typography>
                            <Typography
                              sx={[styles.font, { width: "100px" }]}
                              textAlign={"left"}
                            >
                              ${Math.round(totalAmount)}
                            </Typography>
                          </Box>

                          <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                              <Select
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                value={age}
                                // variant="outlined"
                                onChange={handleChange}
                                size="small"
                              >
                                <MenuItem value={"flat"}>Flat</MenuItem>
                                <MenuItem value={"%"}>%</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          {/* flat */}

                          {age === "flat" && (
                            <>
                              {taxToggle && (
                                <Box
                                  sx={[styles.row, { alignItems: "center" }]}
                                >
                                  <RxCrossCircled
                                    onClick={() => {
                                      handletoggle("tax");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Typography sx={styles.font2}>
                                    Tax:
                                  </Typography>

                                  <TextField
                                    type="number"
                                    onChange={(e) => {
                                      setTextValue(
                                        parseFloat(e.target.value) || 0
                                      );
                                    }}
                                    size="small"
                                    sx={[styles.font, { width: "100px" }]}
                                  />
                                </Box>
                              )}
                              {discountToggle && (
                                <Box
                                  sx={[styles.row, { alignItems: "center" }]}
                                >
                                  <RxCrossCircled
                                    onClick={() => {
                                      handletoggle("disc");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Typography sx={styles.font2}>
                                    Discount:{" "}
                                  </Typography>

                                  <TextField
                                    type="number"
                                    onChange={(e) => {
                                      setDiscountValue(
                                        parseFloat(e.target.value) || 0
                                      );
                                    }}
                                    size="small"
                                    sx={[styles.font, { width: "100px" }]}
                                  />
                                </Box>
                              )}
                              {shippingToggle && (
                                <Box
                                  sx={[styles.row, { alignItems: "center" }]}
                                >
                                  <RxCrossCircled
                                    onClick={() => {
                                      handletoggle("ship");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Typography sx={styles.font2}>
                                    Shipping{" "}
                                  </Typography>

                                  <TextField
                                    type="number"
                                    onChange={(e) => {
                                      setShippingValue(
                                        parseFloat(e.target.value) || 0
                                      );
                                    }}
                                    size="small"
                                    sx={[styles.font, { width: "100px" }]}
                                  />
                                </Box>
                              )}
                              <Box sx={styles.row}>
                                {!discountToggle && (
                                  <Box
                                    sx={[styles.row2, { color: "#0070BA" }]}
                                    onClick={() => {
                                      handletoggle("disc");
                                    }}
                                  >
                                    <GoPlus />
                                    <Typography sx={styles.font}>
                                      Discount
                                    </Typography>
                                  </Box>
                                )}
                                {!taxToggle && (
                                  <Box
                                    sx={[styles.row2, { color: "#0070BA" }]}
                                    onClick={() => {
                                      handletoggle("tax");
                                    }}
                                  >
                                    <GoPlus />
                                    <Typography sx={styles.font}>
                                      Tax
                                    </Typography>
                                  </Box>
                                )}
                                {!shippingToggle && (
                                  <Box
                                    sx={[styles.row2, { color: "#0070BA" }]}
                                    onClick={() => {
                                      handletoggle("ship");
                                    }}
                                  >
                                    <GoPlus />
                                    <Typography sx={styles.font}>
                                      Shipping
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </>
                          )}

                          {/* % */}
                          {age === "%" && (
                            <>
                              {taxToggle2 && (
                                <Box
                                  sx={[styles.row, { alignItems: "center" }]}
                                >
                                  <RxCrossCircled
                                    onClick={() => {
                                      handletoggle2("tax2");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Typography sx={styles.font2}>
                                    Tax:
                                  </Typography>

                                  <TextField
                                    type="number"
                                    onChange={(e) => {
                                      setTextValue(
                                        parseFloat(e.target.value) || 0
                                      );
                                    }}
                                    size="small"
                                    sx={[styles.font, { width: "100px" }]}
                                  />
                                </Box>
                              )}
                              {discountToggle2 && (
                                <Box
                                  sx={[styles.row, { alignItems: "center" }]}
                                >
                                  <RxCrossCircled
                                    onClick={() => {
                                      handletoggle2("disc2");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Typography sx={styles.font2}>
                                    Discount:{" "}
                                  </Typography>

                                  <TextField
                                    type="number"
                                    onChange={(e) => {
                                      setDiscountValue(
                                        parseFloat(e.target.value) || 0
                                      );
                                    }}
                                    size="small"
                                    sx={[styles.font, { width: "100px" }]}
                                  />
                                </Box>
                              )}
                              {shippingToggle2 && (
                                <Box
                                  sx={[styles.row, { alignItems: "center" }]}
                                >
                                  <RxCrossCircled
                                    onClick={() => {
                                      handletoggle2("ship2");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Typography sx={styles.font2}>
                                    Shipping{" "}
                                  </Typography>

                                  <TextField
                                    type="number"
                                    onChange={(e) => {
                                      setShippingValue(
                                        parseFloat(e.target.value) || 0
                                      );
                                    }}
                                    size="small"
                                    sx={[styles.font, { width: "100px" }]}
                                  />
                                </Box>
                              )}
                              <Box sx={styles.row}>
                                {!discountToggle2 && (
                                  <Box
                                    sx={[styles.row2, { color: "#0070BA" }]}
                                    onClick={() => {
                                      handletoggle2("disc2");
                                    }}
                                  >
                                    <GoPlus />
                                    <Typography sx={styles.font}>
                                      Discount
                                    </Typography>
                                  </Box>
                                )}
                                {!taxToggle2 && (
                                  <Box
                                    sx={[styles.row2, { color: "#0070BA" }]}
                                    onClick={() => {
                                      handletoggle2("tax2");
                                    }}
                                  >
                                    <GoPlus />
                                    <Typography sx={styles.font}>
                                      Tax
                                    </Typography>
                                  </Box>
                                )}
                                {!shippingToggle2 && (
                                  <Box
                                    sx={[styles.row2, { color: "#0070BA" }]}
                                    onClick={() => {
                                      handletoggle2("ship2");
                                    }}
                                  >
                                    <GoPlus />
                                    <Typography sx={styles.font}>
                                      Shipping
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </>
                          )}

                          <Box sx={styles.row}>
                            <Typography sx={styles.font2} textAlign={"left"}>
                              Total:{" "}
                            </Typography>
                            <Typography
                              sx={[styles.font, { width: "100px" }]}
                              textAlign={"left"}
                            >
                              ${Math.round(subtotal)}
                            </Typography>
                          </Box>
                          <Box sx={[styles.row, { alignItems: "center" }]}>
                            <Typography sx={styles.font2}>
                              Amount Paid:
                            </Typography>
                            <TextField
                              type="number"
                              onChange={handleAmountPaid}
                              size="small"
                              sx={[styles.font, { width: "100px" }]}
                              value={amountPaid}
                            />
                          </Box>
                          <Box sx={styles.row}>
                            <Typography sx={styles.font2}>
                              Balance Due:{" "}
                            </Typography>
                            <Typography
                              sx={[styles.font, { width: "100px" }]}
                              textAlign={"left"}
                            >
                              ${Math.round(balanceDue)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid items lg={12} marginTop={"20px"}>
                        <Button
                          variant="contained"
                          sx={styles.butn}
                          size="large"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Container>
        </Box>
      }
    </div>
  ) : (
    <InvoicePDF setToggle={setToggle} />
  );
}

export default Home;

const styles = {
  butn: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    backgroundColor: "#163C6B",
  },
  text: {
    fontFamily: "'poppins'",
    fontWeight: 500,
    fontSize: "16px",
  },
  color: {
    backgroundColor: "#0070BA",
  },
  rowItem: {
    display: "flex",
    width: "100%",
    fontFamily: "Poppins, sans-serif",
    alignItems: "center",
  },
  items: {
    width: "100%",
    border: "1px solid grey",
  },
  main: { paddingY: "10px" },
  cont: {
    maxWidth: { lg: "1000px" },
  },
  row2: {
    display: "flex",
    gap: "2px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  row: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  font: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
  },
  font2: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "18px",
  },
  absolute: {
    position: "absolute",
    top: 5,
    left: 0,
    cursor: "pointer",
  },
  mainCont: {
    backgroundColor: "white",
    paddingBottom: "40px",
  },
  logo: {
    width: "100%",
    backgroundColor: "#E6E6E6",
    paddingY: "30px",
  },

  logo2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  h1: {
    fontSize: "45px",
    letterSpacing: "10px",
    color: "#373B44",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bolder",
  },
  box1: {
    height: "42px",
    width: "147px",
    backgroundColor: "#03507C",
    clipPath: " polygon(20% 0, 100% 0%, 100% 99%, 0% 100%);",
  },
  box2: {
    height: "42px",
    width: "94px",
    backgroundColor: "#03507C",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  col3: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "flex-start",
    // alignItems: "flex-end",
    // justifyContent: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },

  logoText: {
    fontFamily: "Bebas Neue, sans-serif",
    color: "#016AAE",
    fontSize: "55px",
    fontWeight: 400,
    lineHeight: "normal",
  },
  logoImg: {
    height: "200px",
    width: "300px",
    border: "1px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  between: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
};
