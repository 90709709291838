import logo from "./logo.svg";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import ResponsiveDrawer from "./Pages/Layout";
import Home from "./Pages/Home";
import Invoice from "./Pages/Invoice";
import { useState } from "react";
import Login from "./Pages/Login";
import { useSelector } from "react-redux";

import Submission from "./Pages/Submission";

function App() {
  const user = useSelector((state) => state.user.user);
  console.log(user, "user");

  const privateRoute = (element) => {
    if (user) {
      return element;
    } else {
      return <Login />;
    }
  };
  const router1 = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<ResponsiveDrawer />}>
        <Route path="/" element={<Home />} />
        <Route path="/invoices" element={privateRoute(<Invoice />)} />
        <Route path="/submit" element={<Submission />} />
        <Route path="/Login" element={<Login />} />

        <Route />
      </Route>
    )
  );
  // const router2 = createBrowserRouter(
  //   createRoutesFromElements(
  //     <Route path="/">
  //       <Route />
  //     </Route>
  //   )
  // );
  return (
    <div className="App">
      <RouterProvider router={router1} />
    </div>
  );
}

export default App;
