import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet } from "react-router-dom";
import logo from "../assests/logo_f.png";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LogoutIcon from "@mui/icons-material/Logout";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LoginOutlined } from "@mui/icons-material";
import toast from "react-hot-toast";
import { store } from "../Redux/configureStore";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/UserSlice";
import { Slide } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => {
    setChecked(true);
  }, []);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const Logout = () => {
    store.dispatch(setUser(false));
    navigate("/");
    toast.success("Logout  Successfully", {
      duration: 3000,
      position: "top-center",
    });
  };
  const navigate = useNavigate();
  const drawer = (
    <div style={{}}>
      <Box sx={{ width: "100%", height: "150px" }}>
        <img
          src={logo}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </Box>
      <List sx={{ color: "white", fontFamily: "Lato, sans-serif" }}>
        {[
          {
            name: "Create Invoice",
            icon: <AddCircleOutlineIcon />,
            bool: false,
            navigation: () => {
              navigate("/");
            },
          },
          {
            name: "Invoices",
            icon: <ReceiptIcon />,
            bool: false,
            navigation: () => {
              navigate("/invoices");
            },
          },
        ].map((text, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ fontWeight: "bold", fontSize: "2rem" }}
          >
            {/* {text.name == "Logout" && user && (
              <>
                <ListItemButton
                  onClick={() => {
                    Logout();
                  }}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2rem",
                    ":hover": {
                      backgroundColor: "#007BFF",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "white" }}>
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      "& .MuiTypography-root": {
                        fontSize: "20px ",
                        fontFamily: "Lato, sans-serif",
                      },
                    }}
                  />
                </ListItemButton>
              </>
            )} */}
            <ListItemButton
              onClick={text.navigation}
              sx={{
                fontWeight: "bold",
                fontSize: "2rem",
                ":hover": {
                  backgroundColor: "#007BFF",
                },
              }}
            >
              <ListItemIcon sx={{ color: "white" }}>{text.icon}</ListItemIcon>
              <ListItemText
                primary={text.name}
                sx={{
                  fontWeight: 800,
                  color: "white",
                  "& .MuiTypography-root": {
                    fontSize: "20px ",
                    fontFamily: "Lato, sans-serif",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
        {user && (
          <ListItem
            disablePadding
            sx={{ fontWeight: "bold", fontSize: "2rem" }}
          >
            <ListItemButton
              onClick={Logout}
              sx={{
                fontWeight: "bold",
                fontSize: "2rem",
                ":hover": {
                  backgroundColor: "#007BFF",
                },
              }}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={{
                  fontWeight: 800,
                  color: "white",
                  "& .MuiTypography-root": {
                    fontSize: "20px ",
                    fontFamily: "Lato, sans-serif",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        backgroundImage:
          'url("https://st2.depositphotos.com/4152719/8388/i/950/depositphotos_83882772-stock-photo-invoice-concept-image-with-business.jpg")',
        height: "150vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <CssBaseline />

      <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "black !important",
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "rgba(0,0,0,0.7) !important",
                backdropFilter: "blur(10px)",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </Slide>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },

          //   background: "rgba(, 0.35)",
          //   boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
          //   backdropFilter: "blur(2px)",
          //   WebkitBackdropFilter: "blur(2px)", // For cross-browser support
          //   borderRadius: "10px",
          //   border: "1px solid rgba(255, 255, 255, 0.18)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "150vh",
            width: "100%",
            backdropFilter: "blur(9px)",
          }}
        ></div>
        <div style={{ position: "relative" }}>
          <Outlet />
        </div>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
