import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import img from "../assests/6543437.png";
import { useNavigate } from "react-router-dom";

const Submission = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Box>
        <Container sx={styles.cont}>
          <Box sx={styles.box}>
            <Box sx={{ height: "280px", width: "280px" }}>
              <img
                src={img}
                alt="oops"
                style={{ height: "100%", width: "100%" }}
              />
            </Box>
            <Box>
              <Typography sx={styles.typo}>
                Invoice Generated Successfully
              </Typography>
              <Box sx={styles.row3}>
                <Button
                  sx={styles.butn}
                  size="large"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  New Invoice
                </Button>
                <Button
                  sx={styles.butn}
                  size="large"
                  onClick={() => {
                    navigate("/invoices");
                  }}
                >
                  All Invoices
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Submission;
const styles = {
  cont: {
    marginTop: "70px",
    maxWidth: { lg: "600px" },
  },
  box: {
    // backgroundColor: "white",
    border: "2px solid transparent",
    borderRadius: "20px",
    width: "100%",
    height: "450px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backdropFilter: "blur(50px)",
    padding: "20px",
    // gap: "60px",
  },
  butn: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    backgroundColor: "white",
    color: "#0070BA",
    fontWeight: "bold",
    ":hover": {
      backgroundColor: "white",
      color: "#0070BA",
    },
  },

  typo: {
    fontFamily: "Poppins, sans-serif",
    color: "white",
    fontWeight: "bold",
    fontSize: "1.8rem",
  },
  row3: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "5px",
  },
};
