import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setToggle, setUser } from "../Redux/UserSlice";
import { db } from "../firebaseConfig";
import { store } from "../Redux/configureStore";
import { collection, getDocs, query, where } from "firebase/firestore";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Login() {
  const dispatch = useDispatch;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const setState = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };
  const handleSubmit = async (formData) => {
    setLoading(true);
    const { username, password } = formData;
    if (!username || !password) {
      toast.error("Please fill all the fields", {
        duration: 3000,
        position: "top-center",
      });
      console.log("Please fill all the fields");
      setLoading(false);
      return;
    }
    const collectRef = collection(db, "adminCredentials");
    const adminQuery = query(
      collectRef,
      where("username", "==", username),
      where("password", "==", password)
    );
    try {
      const doc = await getDocs(adminQuery);
      if (doc.size === 1) {
        store.dispatch(setUser(true));
        store.dispatch(setToggle(true));
        toast.success("Login Successfully", {
          duration: 3000,
          position: "top-center",
        });

        setLoading(false);
        navigate("/invoices");
      } else {
        toast.error("Invalid Credentials", {
          duration: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };
  const handlePress = (e) => {
    if (e.key == "Enter") {
      handleSubmit(formData);
    }
  };
  return (
    <Box sx={styles.bg} onKeyPress={handlePress}>
      <Box sx={styles.bgBlur}>
        <Box sx={styles.login}>
          <Box sx={styles.main}>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.7rem",
                fontFamily: "Lato, sans-serif",
                color: "#0C3D5D",
              }}
            >
              Admin Login
            </Typography>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: "#0C3D5D",
                fontFamily: "Lato, sans-serif",
              }}
            >
              Enter your credentials
            </Typography>
            <TextField
              label={"Email"}
              onChange={(e) => {
                setState("username", e.target.value);
              }}
            />
            <TextField
              label={"Password"}
              onChange={(e) => {
                setState("password", e.target.value);
              }}
            />
            <Button
              sx={styles.btn}
              onClick={() => {
                handleSubmit(formData);
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
const styles = {
  bg: {
    // display: "flex",
    // backgroundImage:
    //   'url("https://st2.depositphotos.com/4152719/8388/i/950/depositphotos_83882772-stock-photo-invoice-concept-image-with-business.jpg")',
    // height: "100vh",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
  },
  bgBlur: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backdropFilter: "blur(10px)",
    height: "100vh",
    width: "100%",
  },
  login: {
    backgroundColor: "white",
    width: "430px",
    height: "420px",
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.7)",
    display: "flex",

    alignItems: "center",
  },
  main: {
    paddingX: "30px",
    fontFamily: "Lato, sans-serif",
    width: "100%",
    display: "flex",
    gap: "15px",
    flexDirection: "column",
  },
  btn: {
    color: "white",
    backgroundColor: "#0C3D5D",
    fontFamily: "Lato, sans-serif",
    paddingX: "10px",
    paddingY: "10px",
    fontWeight: "bold",
    fontSize: "1rem",
    borderRadius: "8px",
    ":hover": { color: " #0C3D5D", backgroundColor: "white" },
  },
};
